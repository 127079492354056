<template>
  <div class="container">
    <div class="containerBox">
      <div class="logoBox">
        <div class="logo">
          <img src="@/assets/images/logo_eqafh_pic@2x.png" />
        </div>
        <p class="title">E起爱缝合</p>
        <p class="desc">尊敬的用户，请您仔细阅读页面下方的《使用条款》和《隐私政策》，E起爱缝合公众号将依此向您提供服务和收集处理您的个人信息。</p>
      </div>
      <div class="InfoBox">
        <div class="info_title">
          <span class="title">个人信息</span>
          <div class="edit" v-show="!showEdit" @click="showEdit = true">
            <img src="@/assets/images/appraise.png" />
            <span>编辑</span>
          </div>
        </div>
        <div class="notEditInfo" v-show="!showEdit">
          <div class="infoItem">
            <span class="label">姓名</span>
            <span class="info">{{Name}}</span>
          </div>
          <div class="infoItem">
            <span class="label">医院</span>
            <span class="info">{{HospitalName}}</span>
          </div>
          <div class="infoItem">
            <span class="label">职称</span>
            <span class="info">{{PositionName}}</span>
          </div>
          <div class="infoItem" v-if="DepartmentName">
            <span class="label">科室</span>
            <span class="info">{{DepartmentName}}</span>
          </div>
        </div>
        <div class="editInfo" v-show="showEdit">
          <div class="infoItem1 bottom">
            <span class="label">姓名</span>
            <div class="inputBox">
              <input class="input" type="text" v-model="Name" placeholder="请输入姓名" />
              <img src="@/assets/images/clean.png" @click="Name = ''" />
            </div>
          </div>
          <div class="infoItem1 bottom">
            <span class="label">医院</span>
            <div class="inputBox">
              <input class="input" type="text" v-model="HospitalKeyWords" placeholder="请输入医院" />
              <img src="@/assets/images/clean.png" @click="cleanHospital" />
              <div class="HospitalList" v-show="showHospitalPicker">
                <span v-for="(item, index) in HospitalColumns" :key="index" @click="selectHospital(item.HospitalName, item.HospitalCode)">{{item.HospitalName}}</span>
              </div>
            </div>
          </div>
          <div class="infoItem1 bottom">
            <span class="label">职称</span>
            <div class="inputBox" @click="showPositionPicker = true">
              <span :class="{'placeholder': PositionName == ''}">{{PositionName ? PositionName : '请选择职称'}}</span>
              <!-- <input class="input" type="text" v-model="PositionName" placeholder="请选择职称" /> -->
              <img src="@/assets/images/xiala.png" />
            </div>
          </div>
          <van-popup v-model="showPositionPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="PositionColumns"
              value-key="Titles"
              @cancel="showPositionPicker = false"
              @confirm="onPositionConfirm"
            />
          </van-popup>
        </div>
        <div class="infoItem1 bottom" v-if="isGetUserInfo && !DepartmentName && !showEdit">
            <span class="label">科室</span>
            <div class="inputBox" @click="showDepartmentPicker = true">
              <span :class="{'placeholder': DepartmentName == ''}">{{DepartmentName ? DepartmentName : '请选择科室'}}</span>
              <!-- <input class="input" type="text" v-model="DepartmentName" placeholder="请选择科室" /> -->
              <img src="@/assets/images/xiala.png" />
            </div>
        </div>
        <div class="infoItem1 bottom" v-if="showEdit">
            <span class="label">科室</span>
            <div class="inputBox" @click="showDepartmentPicker = true">
              <span :class="{'placeholder': DepartmentName == ''}">{{DepartmentName ? DepartmentName : '请选择科室'}}</span>
              <!-- <input class="input" type="text" v-model="DepartmentName" placeholder="请选择科室" /> -->
              <img src="@/assets/images/xiala.png" />
            </div>
        </div>
        <van-popup v-model="showDepartmentPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="DepartmentColumns"
              value-key="Name"
              @cancel="showDepartmentPicker = false"
              @confirm="onDepartmentConfirm"
            />
        </van-popup>
        <div class="infoItem1">
          <span class="label">亚术式</span>
          <div class="inputBox" @click="showOperation">
            <span :class="{'placeholder': OperationValue == ''}">{{OperationValue ? OperationValue : '请选择亚术式'}}</span>
            <!-- <input class="input" type="text" v-model="OperationValue" placeholder="请选择亚术式" /> -->
            <img src="@/assets/images/xiala.png" />
          </div>
        </div>
        <van-popup v-model="showOperationPicker" round position="bottom" :style="{ height: '35%' }">
          <div class="pickerContent">
            <div class="van-picker__toolbar toolbar">
              <button type="button" class="van-picker__cancel" @click="onOperationCancel">取消</button>
              <button type="button" class="van-picker__confirm" @click="onOperationConfirm">确认</button>
            </div>
            <div class="picker_content">
              <div class="pickerBox">
                <div class="picker_block" :class="{'activeBg': item.isChecked}" v-for="(item, index) in tempOperationColumns" :key="index" @click="selectOperation(index)">{{item.Name}}</div>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
      <div class="InfoBox InterestBox">
        <div class="info_title" style="margin-bottom: 20px;">
          <span class="title">选择感兴趣领域<span>（非必填）</span></span>
        </div>
        <div class="block" v-for="(item, index) in list" :key="index">
          <span class="department">{{item.Name}}</span>
          <div class="department_content">
            <span v-for="(sonItem, sonIndex) in item.sonList" :key="sonIndex" @click="selectItem(index, sonIndex)" :class="{'active': sonItem.isChecked}">{{sonItem.SonName}}</span>
          </div>
        </div>
      </div>
      <div class="handleBox">
        <div class="declare" @click="checkedDeclare">
          <img
            :src="
              isCheckedDeclare
                ? require('@/assets/images/system-complete@2x.png')
                : require('@/assets/images/dis_system_complete_icon@2x.png')
            "
          />
          <span class="declareText">我同意<span class="highlight" @click="$router.push('/termsOfUse')">《使用条款》</span>和<span class="highlight" @click="$router.push('/privacyPolicy')">《隐私政策》</span></span>
        </div>
        <div class="handle">
          <div class="btn cancel" @click="reset">重置</div>
          <div class="btn confirm" @click="submit">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import storage from "../../common/utils/storage";

const delay = (function() {
 let timer = 0;
 return function(callback, ms) {
  clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: 'PublicRegister',
  data() {
    return {
      CIAMData: null,
      showEdit: false,
      Phone: '',
      Name: '',
      HospitalKeyWords: '',
      showHospitalPicker: false,
      HospitalColumns: [],
      HospitalCode: '',
      HospitalName: '',
      showPositionPicker: false,
      PositionColumns: [],
      PositionId: '',
      PositionName: '',
      showDepartmentPicker: false,
      DepartmentColumns: [],
      DepartmentCode: '',
      DepartmentName: '',
      showOperationPicker: false,
      tempOperationColumns: [],
      OperationColumns: [],
      OperationValue: '',
      list: [],
      isCheckedDeclare: false,
      isLock: true,
      isGetUserInfo: false
    }
  },
  computed: {
    ...mapState(['Openid', 'userInfo'])
  },
  watch: {
    HospitalKeyWords(newValue) {
      if (newValue == this.HospitalName) {
        this.showHospitalPicker = false;
      } else {
        delay(() => {
          this.GetHospital();
        }, 300);
      }
    },
    DepartmentCode() {
      this.getGetDitWebCode();
    }
  },
  created() {
    if(!this.userInfo) {
      storage.setStore('scope', 'snsapi_userinfo');
      this.$router.push({
        path: '/authorize',
        query: {scope: 'snsapi_userinfo'}
      })
    }
    this.getCIAM();
    this.getPosition();
    this.getDepartment();
    this.getList();
  },
  methods: {
    getCIAM() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetCIAM?openid=' + this.Openid).then(res => {
        this.isGetUserInfo = true;
        let datas = res.Data;
        if(datas) {
          this.CIAMData = datas;
          this.Name = datas.RealName;
          this.HospitalCode = datas.HCode;
          this.HospitalKeyWords =datas.HospitalTitle;
          this.HospitalName = datas.HospitalTitle;
          this.PositionId = datas.PositionalId;
          this.PositionName = datas.PositionalName;
          this.Phone = datas.Phone;

          if(datas.SubjectCode) {
            this.DepartmentCode = datas.SubjectCode;
            this.DepartmentName = datas.SubjectName;
          }
        }
      })
    },
    getList() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetDitWebCodeList').then(res => {
        this.list = res.Data;
        let index = res.Data.findIndex(item => item.Code == "other");
        if (index != -1) {
          this.list.splice(index, 1);
        }
      })
    },
    async GetHospital() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.HospitalKeyWords);
      let data = res.Data;
      if (data.length > 0) {
        this.showHospitalPicker = true;
        this.HospitalColumns = data;
      } else {
        this.showHospitalPicker = false;
        this.HospitalColumns = [];
      }
    },
    selectHospital(HospitalName, HospitalCode) {
      this.HospitalCode = HospitalCode;
      this.HospitalKeyWords = HospitalName;
      this.HospitalName = HospitalName;
      this.showHospitalPicker = false;
    },
    getPosition() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetTitle').then(res => {
        this.PositionColumns = res.Data;
      })
    },
    onPositionConfirm(value) {
      this.PositionId = value.Id;
      this.PositionName = value.Titles;
      this.showPositionPicker = false;
    },
    // 获取科室
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=1&pcode=').then(res => {
        this.DepartmentColumns = res.Data;
      })
    },
    getGetDitWebCode() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + this.DepartmentCode).then(res => {
        this.tempOperationColumns = JSON.parse(JSON.stringify(res.Data));
        this.OperationColumns = JSON.parse(JSON.stringify(res.Data));
        this.OperationValue = '';
      })
    },
    onDepartmentConfirm(value) {
      this.DepartmentCode = value.code;
      this.DepartmentName = value.Name;
      this.showDepartmentPicker = false;
      this.getGetDitWebCode();
    },
    showOperation() {
      if(this.DepartmentName) {
        this.showOperationPicker = true;
        this.tempOperationColumns = JSON.parse(JSON.stringify(this.OperationColumns));
      } else {
        this.$toast('请选择科室！');
      }
    },
    onOperationCancel() {
      this.showOperationPicker = false;
      this.tempOperationColumns = JSON.parse(JSON.stringify(this.OperationColumns));
      this.$forceUpdate();
    },
    onOperationConfirm() {
      this.OperationColumns = JSON.parse(JSON.stringify(this.tempOperationColumns));
      this.showOperationPicker = false;
      this.OperationColumns.forEach(item => {
        if(item.isChecked) {
          this.OperationValue += item.Name + '、';
        }
      });
      this.OperationValue = this.OperationValue.slice(0, this.OperationValue.length - 1);
    },
    selectOperation(index) {
      this.tempOperationColumns[index].isChecked = !this.tempOperationColumns[index].isChecked;
      this.$forceUpdate();
    },
    selectItem(index, sonIndex) {
      this.list[index].sonList[sonIndex].isChecked = !this.list[index].sonList[sonIndex].isChecked;
      this.$forceUpdate();
    },
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    submit() {
      if (!this.Name) {
        this.$toast('请输入姓名！');
        return false;
      }
      if (!this.HospitalName) {
        this.$toast('请输入所在的医院！');
        return false;
      }
      if (!this.PositionName) {
        this.$toast('请选择职称！');
        return false;
      }
      if (!this.DepartmentName) {
        this.$toast('请选择科室！');
        return false;
      }
      if(!this.OperationValue) {
        this.$toast('请选择亚术式！');
        return false;
      }
      if(!this.isCheckedDeclare) {
        this.$toast('请勾选《隐私声明》和《用户协议》并同意！');
        return false;
      }

      let CSList = [];
      this.OperationColumns.forEach(item => {
        if (item.isChecked) {
          CSList.push({
            SonSubjectCode: item.code,
            SonSubjectName: item.Name
          })
        }
      })

      let checkedList = [];
      this.list.forEach(el => {
        el.sonList.forEach(item => {
          if(item.isChecked) {
            checkedList.push({
              SubId: item.Id,
              SubPCode: item.SonPCode,
              SubCode: item.SonCode,
              SubName: item.SonName
            })
          }
        })
      });
      // let data = {
      //   OpenID: this.Openid,
      //   RealName: this.Name,
      //   Phone: this.Phone,
      //   HCode: this.HospitalCode,
      //   HospitalTitle: this.HospitalName,
      //   PositionalId: this.PositionId,
      //   PositionalName: this.PositionName,
      //   SubjectCode: this.DepartmentCode,
      //   SubjectName: this.DepartmentName,
      //   CSList: CSList,
      //   interested: checkedList
      // }
      this.CIAMData.OpenID = this.Openid;
      this.CIAMData.RealName = this.Name;
      this.CIAMData.Phone = this.Phone;
      this.CIAMData.HCode = this.HospitalCode;
      this.CIAMData.HospitalTitle = this.HospitalName;
      this.CIAMData.PositionalId = this.PositionId;
      this.CIAMData.PositionalName = this.PositionName;
      this.CIAMData.SubjectCode = this.DepartmentCode;
      this.CIAMData.SubjectName = this.DepartmentName;
      this.CIAMData.CSList = CSList;
      this.CIAMData.interested = checkedList;
      // console.log(data)
      if (this.isLock) {
        this.isLock = false;
        this.$axios.post('/Api/Api/Web/GetWxUser/UserRegisterNew', this.CIAMData).then((res) => {
          if (res.RetCode == '10000') {
            this.isLock = true;
            storage.setStore('Token', res.Data.Token);
            // this.$router.push('/transferPage');
            let sid = sessionStorage.getItem("sid");
            let key = sessionStorage.getItem("key");
            let uri = sessionStorage.getItem("uri");
            let path = sessionStorage.getItem("path");
            let redirectUrl = sessionStorage.getItem("redirectUrl");
            if (sid && uri) {
              location.href = decodeURIComponent(uri);
            } else if (key && uri) {
              location.href = decodeURIComponent(uri + '&uid=' +  this.userInfo.Id);
            } else if (this.userInfo.SubjectCode == "gu" && uri) {
              location.href = decodeURIComponent(uri);
            } else if (path) {
              this.$router.push(path);
            } else if (redirectUrl) {
              this.$router.push(redirectUrl);
              sessionStorage.removeItem('redirectUrl');
            } else {
              this.$router.push('/transferPage');
            }
          } else {
            this.isLock = true;
            this.$toast(res.RetMsg);
          }
        })
      }
    },
    reset() {
      this.list.forEach(item => {
        item.sonList.forEach(sonItem => {
          sonItem.isChecked = false;
        })
      })
      this.$forceUpdate();
    },
    cleanHospital() {
      this.HospitalKeyWords = '';
      this.HospitalName = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  padding-bottom: 200px;
  .containerBox {
    position: relative;
    top: 80px;
    padding: 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
  }
  .logoBox {
    position: relative;
    top: -36px;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 12px;
    .logo {
      position: absolute;
      top: -35px;
      left: 50%;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #FDFDFD;
      transform: translateX(-40px);
      img {
        width: 84%;
        margin-top: 7px;
        margin-left: 6px;
      }
    }
    .title {
      font-size: 16px;
      color: #333333;
      text-align: center;
      margin-top: 44px;
      font-weight: bold;
    }
    .desc {
      font-size: 10px;
      color: #999999;
      margin-top: 12px;
      line-height: 14px;
    }
  }
  .InfoBox {
    padding: 12px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: -25px;
    .info_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        span {
          color: #C70C3C;
        }
      }
      .edit {
        font-size: 12px;
        color: #CA001B;
        img {
          width: 14px;
          height: 14px;
          margin-right: 2px;
          vertical-align: middle;
        }
      }
    }
    .notEditInfo {
      margin: 15px 0 12px;
      padding-bottom: 8px;
      border-bottom: 1px solid #EEEEEE;
    }
    .infoItem {
      font-size: 14px;
      line-height: 29px;
      .label {
        display: inline-block;
        width: 50px;
        color: #999999;
      }
      .info {
        color: #333333;
      }
    }
    .infoItem1 {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      .label {
        display: inline-block;
        width: 50px;
        color: #999999;
      }
      .inputBox {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        width: 0;
        height: 36px;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 10px;
        color: #333333;
        box-sizing: border-box;
        .input {
          flex: 1;
          border: none;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .bottom {
      margin-bottom: 10px;
    }
    .editInfo {
      margin-top: 15px;
    }
    .HospitalList {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 9;
      width: 100%;
      max-height: 200px;
      background-color: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0 8px 12px #ebedf0;
      overflow-y: scroll;
      span {
        padding: 5px 10px;
        display: block;
        font-size: 12px;
        line-height: 24px;
      }
      span:not(:last-child) {
        border-bottom: 1px solid #ebebeb;
      }
    }
  }
  .picker_content {
    padding: 20px 6px;
    .pickerBox {
      display: flex;
      flex-wrap: wrap;
    }
    .picker_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(calc(100% / 3) - 12px);
      height: 36px;
      text-align: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      box-sizing: border-box;
      margin: 6px;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
    }
    .activeBg {
      border: none;
      color: #C70C3C;
      background-image: url('../../assets/images/select_system_btn@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .InterestBox {
    margin-top: 10px;
    .block {
      margin-bottom: 18px;
      .department {
        display: block;
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        margin: 6px 0 0;
      }
      .department_content {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        border-bottom: 1px solid #EFEDED;
        padding: 8px 0;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: calc(calc(100% / 4) - 6px);
          height: 36px;
          background-color: #F5F6F7;
          border-radius: 36px;
          margin: 8px 3px;
          box-sizing: border-box;
        }
        .active {
          color: #FFFFFF;
          background-color: #C70C3C;
        }
      }
    }
  }
  .handleBox {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 12px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 4px 12px #dddddd;
    .declare {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #666666;
      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      .highlight {
        color: #CA001B;
      }
    }
    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      margin: 15px 0;
      .btn {
        width: 48%;
        height: 44px;
        line-height: 44px;
        border-radius: 44px;
        text-align: center;
      }
      .cancel {
        border: 1px solid #C70C3C;
        color: #C70C3C;
        box-sizing: border-box;
      }
      .confirm {
        color: #FFFFFF;
        background-color: #C70C3C;
      }
    }
    .reset {
      color: #000000;
      font-size: 14px;
      text-align: center;
    }
  }
}

/*webkit 内核浏览器*/
input::-webkit-input-placeholder{
  font-size: 14px;
  color: #999999;
}
/*Mozilla Firefox 19+*/
input::-moz-placeholder{
  font-size: 14px;
  color: #999999;
}
/*Mozilla Firefox 4 to 18*/
input:-moz-placeholder{
  font-size: 14px;
  color: #999999;
}
/*Internet Explorer 10+*/
input:-ms-input-placeholder{
  font-size: 14px;
  color: #999999;
}


.placeholder {
  color: #999999 !important;
}
</style>